import { gql } from "@apollo/client";
import { PortfolioListItemContentFragment } from "generated/graphql";
import { rr } from "lib/reverseRouter";
import Link from "next/link";
import { UserIcon } from "./elements/UserIcon";
import styles from "./PortfolioListItem.module.scss";
import { ScissorsImage } from "./ScissorsImage";

gql`
  fragment PortfolioListItemContent on Portfolio {
    id
    user {
      id
      screenName
      displayName
      iconImage {
        id
        url
      }
    }
    bannerImage {
      id
      url
    }
    categories {
      id
      title
    }
  }
`;

type PortfolioListItemProps = {
  portfolio: PortfolioListItemContentFragment;
};

export const PortfolioListItem: React.FC<PortfolioListItemProps> = ({
  portfolio,
}) => {
  return (
    <Link
      {...rr.site.mangafolio.index({
        userScreenName: portfolio.user.screenName,
      })}
      className={styles.wrapper}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className={styles.cover_img}>
        {portfolio?.bannerImage ? (
          <ScissorsImage
            templateUrl={portfolio.bannerImage.url}
            width={664}
            height={256}
          />
        ) : (
          <img src={"/images/portfolio_cover.png"} />
        )}
      </div>
      <div className={styles.data_wrapper}>
        <div className={styles.user_container}>
          <div className={styles.user_icon}>
            <UserIcon userIconUrl={portfolio.user.iconImage?.url} size={112} />
          </div>
          <h3 className={styles.user_name}>{portfolio.user.displayName}</h3>
        </div>
        <ul className={styles.category_container}>
          {portfolio.categories.map((category, index) => (
            <li key={category.id}>
              <p>{category.title}</p>
              <span>{index < portfolio.categories.length - 1 && "/"}</span>
            </li>
          ))}
        </ul>
      </div>
    </Link>
  );
};
