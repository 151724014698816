import {
  MouseEventHandler,
  TouchEventHandler,
  UIEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

export type UseCarouselOptions = {
  /** 直近の要素にスナップするかどうか。デフォルトは true */
  snap?: boolean;
};

export type UseCarouselResult = {
  /** 次のページにスクロールする */
  next: (options?: ScrollOptions) => void;
  /** 前のページにスクロールする */
  prev: (options?: ScrollOptions) => void;
  /** 最後のページにスクロールする */
  last: () => void;
  /** 最初のページにスクロールする */
  first: () => void;
  /** 次のページにスクロールできるかどうか */
  canGoToNext: boolean;
  /** 前のページにスクロールできるかどうか */
  canGoToPrev: boolean;
  /** タッチ端末とみなしたかどうか */
  isTouch: boolean;
  /** refやイベントハンドラーなど */
  bond: UseCarouselResultBond;
};

type UseCarouselResultBond = {
  ref: React.Ref<never>;
  onMouseDown: MouseEventHandler;
  onMouseUp: MouseEventHandler;
  onMouseLeave: MouseEventHandler;
  onMouseMove: MouseEventHandler;
  onClickCapture: MouseEventHandler;
  onTouchStart: TouchEventHandler;
  onScroll: UIEventHandler;
};

export type ScrollOptions = {
  /** スクロールの間隔。デフォルトは `"child"` 。 */
  scrollInterval: ScrollInterval;
};

/**
 * スクロールの間隔。
 *
 * - `"child"` : カルーセル内の個々の要素の幅でスクロールする。
 * - `"window"` : ウィンドウ幅でスクロールする。
 */
type ScrollInterval = "child" | "window";

const defaultScrollOptions: ScrollOptions = { scrollInterval: "child" };

/**
 * useCarouselはカルーセルの実装を提供するhook。
 *
 * カルーセル要素は `overflow: scroll;` でレイアウトが組まれている前提で動作する。
 *
 * 非タッチ端末では要素をドラッグしてスクロールする実装を提供するが、タッチ端末では提供しないので、`scroll-snap-type` で代用すること。
 */
export function useCarousel(options?: UseCarouselOptions): UseCarouselResult {
  const { snap }: Required<UseCarouselOptions> = { snap: true, ...options };

  const [isTouch, setIsTouch] = useState(false);

  const onTouchStart: TouchEventHandler<HTMLElement> = useCallback(() => {
    // タッチ端末の場合scroll-snap-typeに任せる方が挙動が安定するため、独自実装をオフにする
    setIsTouch(true);
  }, []);

  const onMouseDown: MouseEventHandler = useCallback(
    (e) => {
      // タッチ端末の場合はイベントを無視する
      if (isTouch) return;
      // カルーセルの子要素をドラッグできないようにする
      e.preventDefault();
    },
    [isTouch]
  );

  const [isManuallyScrolled, setIsManuallyScrolled] = useState(false);

  const onMouseMove: MouseEventHandler = useCallback(
    (e) => {
      // タッチ端末の場合はイベントを無視する
      if (isTouch) return;
      // マウスボタンが押されていなければイベントを無視する
      if (e.buttons !== 1) return;
      // マウスが動いたぶんだけカルーセルをスクロールする
      e.currentTarget.scrollLeft -= e.movementX;
      // 手でドラッグしたことを記録
      setIsManuallyScrolled(true);
    },
    [isTouch]
  );

  const onClickCapture: MouseEventHandler = useCallback(
    (e) => {
      // タッチ端末の場合はイベントを無視する
      if (isTouch) return;
      // ドラッグでスクロールされた場合
      if (isManuallyScrolled) {
        // クリックイベントを無視する
        e.preventDefault();
        // スクロール判定をリセット
        setIsManuallyScrolled(false);
      }
    },
    [isTouch, isManuallyScrolled]
  );

  // 前後のページに移動できるかどうか（≈カルーセルの最初または最後の要素を表示しているかどうか）
  const [canGoToNext, setCanGoToNext] = useState(false);
  const [canGoToPrev, setCanGoToPrev] = useState(false);
  const setCanGoto = useCallback((carousel: HTMLElement) => {
    // 右端に残っているスクロール量が1px以上だったら右にスクロール可能
    setCanGoToNext(
      carousel.scrollWidth - carousel.scrollLeft - carousel.clientWidth > 1
    );
    // 左端に残っているスクロール量が1px以上あれば左にスクロール可能
    setCanGoToPrev(carousel.scrollLeft > 1);
  }, []);

  const onScroll: UIEventHandler<HTMLElement> = useCallback(
    (e) => {
      setCanGoto(e.currentTarget);
    },
    [setCanGoto]
  );

  const [carousel, setCarousel] = useState<HTMLElement | null>(null);
  const carouselRef = useCallback((el: HTMLElement | null) => {
    setCarousel(el);
  }, []);

  useEffect(() => {
    const mutationObserver = new MutationObserver(() => {
      if (!carousel) return;
      setCanGoto(carousel);
    });

    if (carousel) {
      mutationObserver.observe(carousel, {
        childList: true,
      });
    }

    return () => {
      mutationObserver.disconnect();
    };
  }, [carousel, setCanGoto]);

  useEffect(() => {
    if (!carousel) return;
    setCanGoto(carousel);
  }, [carousel, setCanGoto]);

  const onMouseUpLeave: MouseEventHandler = useCallback(
    (e) => {
      if (!snap) return;

      if (!carousel) return;

      // タッチ端末の場合はイベントを無視する
      if (isTouch) return;

      const carouselRect = carousel.getBoundingClientRect();

      // 最後の要素までスクロールしている場合はそこにスナップする
      // 最後の要素が半分以上表示されていたら最後までスクロールしたとみなす
      const lastChild = e.currentTarget.lastChild as HTMLElement | null;
      if (lastChild) {
        const lastChildRect = lastChild.getBoundingClientRect();
        // 右端までの残りスクロール量
        const offset = lastChildRect.right - carouselRect.right;
        // 残りスクロール量が要素の幅の半分以下 == 要素が半分以上表示されている
        if (offset < lastChildRect.width * 0.5) {
          e.currentTarget.scrollBy({
            behavior: "smooth",
            left: offset,
          });
          return;
        }
      }

      // 半分以上Viewportに入っている要素のうち、最初に見つかったものにスクロール（スナップ）する
      for (const el of e.currentTarget.children) {
        const rect = el.getBoundingClientRect();
        const left = rect.x - carouselRect.x;
        if (left > rect.width * -0.5) {
          e.currentTarget.scrollBy({
            behavior: "smooth",
            left: left,
          });
          break;
        }
      }
    },
    [snap, carousel, isTouch]
  );

  const next = useCallback(
    (options: ScrollOptions = defaultScrollOptions) => {
      if (!carousel) return;
      const left = getScrollByLeftNext(carousel, options.scrollInterval);
      carousel.scrollBy({
        behavior: "smooth",
        top: 0,
        left,
      });
    },
    [carousel]
  );

  const prev = useCallback(
    (options: ScrollOptions = defaultScrollOptions) => {
      if (!carousel) return;
      const left = getScrollByLeftPrev(carousel, options.scrollInterval);
      carousel.scrollBy({
        behavior: "smooth",
        top: 0,
        left,
      });
    },
    [carousel]
  );

  const last = useCallback(() => {
    if (!carousel) return;
    carousel.scrollTo({
      behavior: "smooth",
      top: 0,
      left: 99999, // 実際の右端を計算してもいいが、とりあえず大きな値をセットすれば用途的には問題ない
    });
  }, [carousel]);

  const first = useCallback(() => {
    if (!carousel) return;
    carousel.scrollTo({
      behavior: "smooth",
      top: 0,
      left: 0,
    });
  }, [carousel]);

  const bond: UseCarouselResultBond = useMemo(
    () => ({
      ref: carouselRef as React.Ref<never>,
      onClickCapture,
      onTouchStart,
      onMouseDown,
      onMouseUp: onMouseUpLeave,
      onMouseLeave: onMouseUpLeave,
      onMouseMove,
      onScroll,
    }),
    [
      carouselRef,
      onTouchStart,
      onMouseDown,
      onMouseUpLeave,
      onMouseMove,
      onClickCapture,
      onScroll,
    ]
  );

  return {
    next,
    prev,
    last,
    first,
    canGoToNext,
    canGoToPrev,
    isTouch,
    bond,
  };
}

function getScrollByLeftNext(
  carousel: Element,
  scrollInterval: ScrollInterval
): number {
  if (scrollInterval === "window") {
    return window.innerWidth;
  }

  if (scrollInterval === "child") {
    const carouselRect = carousel.getBoundingClientRect();
    // Viewport内に表示されている要素のうち、2番目に表示されているものにスクロールする
    for (const el of carousel.children) {
      const rect = el.getBoundingClientRect();
      const left = rect.x - carouselRect.x;
      // left が 0.n px だとスクロールされないので 1px 以上ズレている要素を選ぶ
      if (left > 1) {
        return left;
      }
    }
  }

  return 0;
}

function getScrollByLeftPrev(
  carousel: Element,
  scrollInterval: ScrollInterval
): number {
  if (scrollInterval === "window") {
    return -window.innerWidth;
  }

  if (scrollInterval === "child") {
    const carouselRect = carousel.getBoundingClientRect();
    // Viewport外に表示されている要素のうち一番最後（Viewport内に最初に表示されている要素の一つ前）に表示されているものにスクロールする
    for (const el of Array.from(carousel.children).reverse()) {
      const rect = el.getBoundingClientRect();
      const left = rect.x - carouselRect.x;
      // left が -0.n px だとスクロールされないので -1px 以上ズレている要素を選ぶ
      if (left < -1) {
        return left;
      }
    }
  }

  return 0;
}
